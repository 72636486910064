import React, { useEffect } from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { CLIENT, COLLABORATION, PAID, propTypes } from '../../../util/types';
import { Form, PrimaryButton, FieldTextInput, IconInquiry, Heading, FieldSelect,} from '../../../components';

import css from './InquiryForm.module.css';
import { useState } from 'react';

const ErrorMessage = props => {
  const { error } = props;
  // No transaction process attached to listing
  return error ? (
    <p className={css.error}>
      {error.message === 'No transaction process attached to listing' ? (
        <FormattedMessage id="InquiryForm.sendInquiryErrorNoProcess" />
      ) : (
        <FormattedMessage id="InquiryForm.sendInquiryError" />
      )}
    </p>
  ) : null;
};

// NOTE: this InquiryForm is only for booking & purchase processes
// The default-inquiry process is handled differently
const InquiryFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        authorDisplayName,
        sendInquiryError,
        listingType,
        values,
        publishedListings,
        userType,
        jobInformation,
        isCollaboration,
        form,
        modalGender,
        height,
        isAlreadyEnquired
      } = fieldRenderProps;
 
      const [jobType, setJobType] = useState(null);
      const getjobInformation = (listingId) => {
        const jobDetails = Array.isArray(publishedListings) && publishedListings.filter((listing) => listing.listingId === listingId);
        const clientGender= jobDetails && jobDetails[0] && jobDetails[0].clientGender;
        const jobType = jobDetails && jobDetails[0] && jobDetails[0].jobInformation;
        const clientHeight= jobDetails && jobDetails[0] && jobDetails[0].heightRange;
        setJobType(jobType);
        form.change("jobInformation", jobType);
        form.change("clientGender",clientGender )
        form.change("clientHeight",clientHeight )
        return jobType
      }

      const compareHeightAndGender = (height, modalGender) => {
        const minHeight = Array.isArray(values?.clientHeight) && values?.clientHeight.length && values?.clientHeight[0]
        const maxHeight = Array.isArray(values?.clientHeight) && values?.clientHeight.length>1 && values?.clientHeight[1]
    
        const isHeightInRange = height/100 >= minHeight && height/100 <= maxHeight;
        const isMale =Array.isArray(values?.clientGender) && values?.clientGender.length && values?.clientGender[0] === modalGender;
        return isHeightInRange && isMale;
    }
    
    const result = compareHeightAndGender(height, modalGender);
    

      useEffect(() => {
        getjobInformation(values.jobListingId)
      }, [values && values.jobListingId])


      const messageLabel = intl.formatMessage(
        {
          id: 'InquiryForm.messageLabel',
        },
        { authorDisplayName }
      );
      const messagePlaceholder = intl.formatMessage(
        {
          id: 'InquiryForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'InquiryForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress || (userType===CLIENT && !values.jobListingId) 
      // || (jobType === PAID && !values.inquiryPrice) || (jobInformation && jobInformation !== COLLABORATION && !values.inquiryPrice) 
      || !result;
      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          <IconInquiry className={css.icon} />
          <Heading as="h2" rootClassName={css.heading}>
            <FormattedMessage id="InquiryForm.heading" values={{ listingTitle }} />
          </Heading>
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            label={messageLabel}
            placeholder={messagePlaceholder}
            validate={messageRequired}
          />


          {userType === CLIENT ?
            <div className={css.jobListingInput}>
              <FieldSelect
                id={"jobListingId"}
                className={css.quantityField}
                name="jobListingId"
                label={"Job Listings"}
              >
                <option disabled value="">
                  {intl.formatMessage({ id: 'ProductOrderForm.selectQuantityOption' })}
                </option>
                {Array.isArray(publishedListings) && publishedListings.map(l => {
                  return (
                    
                    <option key={l.listingId} value={l.listingId}>
                      {l.title}
                    </option>
                  )
                }
                )}
              </FieldSelect>
            </div> : null}
            {values?.jobListingId && !result ? <div className={css.criteriaText}>
            It doesn't look like you meet the criteria for this job.
            </div> : null}
          {/* { values?.jobListingId && result && jobType == PAID || jobInformation === PAID ?
            <div className={css.jobListingInput}>
              <FieldTextInput
                id={`${formId}inquiryPrice`}
                name="inquiryPrice"
                className={css.description}
                type="number"
                label={intl.formatMessage({ id: 'NegotiationForm.Price' })}
                placeholder={intl.formatMessage({
                  id: 'EditListingPricingAndStockForm.priceInputPlaceholder',
                })}
              /> 
            </div> : null} */}

          <div className={submitButtonWrapperClassName}>
            <ErrorMessage error={sendInquiryError} />
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="InquiryForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

InquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendInquiryError: null,
};

InquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendInquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const InquiryForm = compose(injectIntl)(InquiryFormComponent);

InquiryForm.displayName = 'InquiryForm';

export default InquiryForm;
